body, html {
    background-color: #323232;
    height: 100%;
}

.textInput::placeholder {
    color: #97bace;
}

.link {
    color: hsla(205, 49%, 66%, 1);
}

.addInstance {
    cursor: pointer;
}

.addInstance:hover {
    text-decoration: underline;
}

#root {
    height: 100%;
}

.toolItem:hover, .folderName:hover {
    text-decoration: underline;
}

input:focus, textarea:focus, select:focus, audio:focus, source:focus {
    outline: none;
}

@keyframes rotating {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}

.rotating {
    animation: rotating 2s linear infinite;
}

@keyframes jumping {
    0% {
        bottom: 0;
        transform: scale(1);
    }
    15% {
        bottom: 12px;
        transform: scale(1.2);
    }
    30% {
        bottom: 0;
        transform: scale(1);
    }
}

.jumping {
    position: relative;
    animation: jumping 2s linear 2s 1;
}

.jumpingDrop {
    position: relative;
    animation: jumping 2s linear 2s infinite;
}
